import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";


const ConceptArtist = ({ props }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleImageClick = (index, category) => {
    setSelectedImageIndex(index);
    setSelectedCategory(category);
    setOpenModal(true);
  };

  const closePictureModal = () => {
    setOpenModal(false);
  };

  const handleImageNextClick = () => {
    setSelectedImageIndex(
      (prev) => (prev + 1) % props.images[selectedCategory].length
    );
  };

  const handleImagePrevClick = () => {
    setSelectedImageIndex((prev) => {
      const newIndex =
        (prev - 1 + props.images[selectedCategory].length) %
        props.images[selectedCategory].length;
      return newIndex;
    });
  };

  return (
    <div id="Concept-Artist" className="md:container m-auto mt-4">
      {Object.entries(props.images).map(([category, images], index) => (
        <div key={index} className="category-section">
          <h2 className="text-white text-center py-3 md:text-2xl uppercase">
            {category}
          </h2>
          <div className="grid md:grid-cols-4 gap-2 mx-2 md:m-auto border-b-4 overflow-hidden p-2 bg-black rounded-md bg-opacity-50">
            {images.length > 0 ? (
              images.map((image, imgIndex) => (
                <div
                  key={imgIndex}
                  className="hover:scale-105 duration-300 cursor-pointer hover:shadow-md hover:shadow-white bg-white"
                  onClick={() => handleImageClick(imgIndex, category)}
                >
                  <img src={image} alt="" loading="lazy" />
                </div>
              ))
            ) : (
              <p
                style={{ color: "#777", fontStyle: "italic" }}
                className="text-sm"
              >
                No images yet
              </p>
            )}
          </div>
        </div>
      ))}

      <AnimatePresence>
        {openModal && (
          <motion.div
            className="fixed top-0 left-0 z-[10] w-full h-full bg-black bg-opacity-90 overflow-hidden text-white duration-300 transition-all"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="md:mt-28 mt-5 mx-1 grid justify-center items-center overflow-hidden">
              <div className="flex justify-start overflow-hidden w-screen">
                <div
                  className="w-20 next z-20 bg-gray-600 text-center py-1 md:mx-3 mx-1 mb-3 text-[20px] rounded-md cursor-pointer active:bg-transparent hover:bg-slate-500"
                  onClick={closePictureModal}
                >
                  <h1 className="next text-sm">{`Go back`}</h1>
                </div>
              </div>

              <div className="fixed md:flex left-0 right-0 top-20 justify-center items-center  md:ml-4 z-[10] md:mr-4">
                <div
                  className="text-sm w-20 hidden md:grid  bg-gray-700 text-center py-2 md:mx-3 mx-1 mb-3 rounded-sm cursor-pointer active:bg-transparent bg-opacity-75 text-[25px] next hover:bg-slate-500"
                  onClick={handleImagePrevClick}
                >
                  {` < Prev `}
                  
                </div>
                <motion.div
                key={selectedImageIndex}
                initial={{ opacity: 1, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0 }}
                transition={{ type: "spring", stiffness: 100, damping: 10 }}
              >
                <img
                  src={props.images[selectedCategory][selectedImageIndex]}
                  alt=""
                  className="md:w-[600px] md:h-[500px] 2xl:w-[1000px] 2xl:h-[800px] h-[55vh] w-[410px] m-auto md:top-24 md:left-0 md:right-0 rounded-md px-3 md:px-0"
                />
              </motion.div>

              {/* Mobile Button */}

             <div className="flex justify-center gap-2 mt-4 md:hidden">
             <div
                  className=" text-sm w-20 bg-gray-700 hover:bg-slate-500 text-center py-2 md:mx-3 mx-1 mb-3 rounded-sm cursor-pointer active:bg-transparent bg-opacity-75 next text-[25px]"
                  onClick={handleImageNextClick}
                >
                  {` < Prev `}
                </div>
                <div
                  className=" text-sm w-20 bg-gray-700 hover:bg-slate-500 text-center py-2 md:mx-3 mx-1 mb-3 rounded-sm cursor-pointer active:bg-transparent bg-opacity-75 next text-[25px]"
                  onClick={handleImageNextClick}
                >
                 {` Next > `}
                </div>
             </div>

{/* Mobile Button */}
                <div
                  className="hidden md:grid text-sm w-20 bg-gray-700 hover:bg-slate-500 text-center py-2 md:mx-3 mx-1 mb-3 rounded-sm cursor-pointer active:bg-transparent bg-opacity-75 next text-[25px]"
                  onClick={handleImageNextClick}
                >
                 {` Next > `}
                </div>
              </div>
              
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ConceptArtist;
