import React, { useState, useEffect } from "react";
import './index.css'
import Header from "./Components/header/header";
import { createBrowserRouter, Outlet, RouterProvider, ScrollRestoration } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { CloudinaryImages } from "./Components/Portfolio/CloudinaryApI/cloudinaryApi";
import Home from "./Pages/home";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import Footer from "./Components/footer/footer";

const Layout = () => {
  return (
    <div>
      <Header />
      <ScrollRestoration />
      <Outlet />
      <Footer/>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
        loader: CloudinaryImages,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
]);

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await CloudinaryImages(); // Perform the required data loading or API calls
        setLoading(false); // Set loading to false when data is successfully loaded
      } catch (error) {
        // console.error("Error fetching data:", error);
        
        setLoading(false); // Set loading to false even in case of errors
        // throw error
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      // Cleanup logic if needed
    };
  }, []);

  return (
    <div>
      {loading && <LinearProgress />}
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
