import React, { useState } from "react";
import "./index.css";
import ErrorImage from "./image.png";
import Header from "../Components/header/header";
import Footer from "../Components/footer/footer";

const ErrorBoundary = () => {
  const [imageLoaded, setImageLoaded] = useState(true);

  return (
    <div
      className=" w-full"
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Header />
      <div className="p-[50px] text-center grid justify-center">
        <div className=" h-[200px] border-4 border-blue-400 rounded-full relative z-[30]">
          {imageLoaded ? ( // Render the main image if it has loaded successfully
            <img
              className="absolute w-full h-full rounded-full "
              src="original-image-url.jpg"
              alt=""
              onLoad={() => setImageLoaded(true)} // Update state when the main image loads
              onError={() => setImageLoaded(false)} // Update state if the main image fails to load
            />
          ) : (
            // Render the fallback image if the main image failed to load
            <img
              className="absolute w-full h-full rounded-full hover:scale-105"
              src={ErrorImage}
              alt=""
            />
          )}
        </div>
        <h1 className="text-2xl text-white mb-3 font-bold">Something went wrong</h1>
        <h2 className="text-lg text-white font-semibold mb-2">
          Check your network connection and try again
        </h2>
        <div className="text-lg text-white font-semibold flex gap-2 justify-center align-middle">
          {" "}
          Click{" "}
          <div
            onClick={() => window.location.reload()}
            className="font-bold underline cursor-pointer hover:text-blue-400"
          >
            here
          </div>{" "}
          to reload
        </div>
      </div>
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
};

export default ErrorBoundary;
