import image from "./image.webp";

const AboutMe = () => {
  function expand() {
    let dots = document.getElementById("dots");
    let moreText = document.getElementById("more");
    let btnText = document.getElementById("myBtn");
    let main = document.getElementById('paragraph')
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more";
      moreText.style.display = "none";
      moreText.style.transition='ease 1s'
      main.scrollIntoView();
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less";
      moreText.style.display = "inline";
      moreText.style.transition='ease 1s all'
      main.scrollIntoView();
    }
  }

  return (
   <div className="grid justify-center bg-black bg-opacity-40">
     <div className="md:w-[800px] mt-3 mb-3 mx-3 md:mx-0">
      <div className="md:flex gap-7 flex m-auto" id="paragraph">
        <img src={image} alt="" className=" md:h-52 md:w-52 rounded-md h-48" />
        <div className="md:text-xl md:w-96 text-white text-sm">
          <h1 className="underline mb-3">ABOUT ME</h1>
          ''THERE'S NO SUCH THING AS ''PERFECT ART''. THERE'S ONLY ART THAT IS
          BETTER THAN WHAT YOU USED TO DRAW''.
          <br />
          <h5 className=" mt-5 text-end md:text-lg font-bold mb-3 text-sm">~SEBASTIAN NILSSON</h5>
        </div>
      </div>
      <div  className=" m-auto">
      <div className=" text-white text-sm md:text-lg transition-all">
        Hello, I'm Sebastian Nilsson, a dedicated motion designer with a passion
        for concept art, 2D animation, and 3D sculpting. Throughout my journey,
        I have developed proficiency in various software, including Clip Studio
        Paint, Adobe After Effects, Adobe Premiere, ZBrush, and Blender. As
        <span id="dots">...</span>
       
        <span id="more" className="duration-300">
          {" "}
          someone who continually strives for excellence, I am always eager to
          take on new challenges and collaborate effectively with my team. Since
          childhood, I have been driven by a desire to create inspiring stories
          with superheroes, aiming to motivate and uplift others through my
          work. In addition to my technical skills, I bring a strong work ethic
          and a relentless pursuit of my goals, no matter how difficult the
          situation. My personal interests, such as drawing comics, working out,
          and playing video games, fuel my creativity and innovation in my
          professional projects. I aspire to inspire others through my vision
          and dedication, encouraging them to pursue their dreams with the same
          passion. I am excited about the possibility of bringing my creativity,
          skills, and determination to your team and contributing to meaningful
          projects that make a difference.
        </span>{" "}
      </div> 
   
      <button onClick={expand} id="myBtn" className="w-32 h-9 mt-3 md:mt-0 bg-gray-500 text-yellow-50 hover:bg-gray-300 hover:text-black">Read More</button>
    
      </div>
     
    </div>
   </div>
  );
};
export default AboutMe;
