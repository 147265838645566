import { useRef, useState } from "react";
import { MdFullscreen, MdPause, MdPlayArrow } from "react-icons/md";
const Showreel = ({props})=>{
    const videoRef = useRef(null);
    const [IsPlaying, setIsPlaying] = useState(false);
    const handleFullscreen = () => {
        if (videoRef.current) {
          if (videoRef.current.requestFullscreen) {
            videoRef.current.requestFullscreen();
          } else if (videoRef.current.mozRequestFullScreen) {
            // Firefox
            videoRef.current.mozRequestFullScreen();
          } else if (videoRef.current.webkitRequestFullscreen) {
            // Chrome, Safari and Opera
            videoRef.current.webkitRequestFullscreen();
          } else if (videoRef.current.msRequestFullscreen) {
            // IE/Edge
            videoRef.current.msRequestFullscreen();
          }
          videoRef.current.play(); // Optionally start playing the video
        }
      };
      const playIcon = () => {
        return (
          <div className="flex items-center justify-center">
          
            <MdPlayArrow className="text-2xl text-white" /> Play
          </div>
        );
      };
      const pauseIcon = () => {
        return (
          <div className="flex items-center justify-center">
            {" "}
            <MdPause className="text-2xl text-white" /> Pause
          </div>
        );
      };
      const togglePlayBtn = () => {
        const vid = document.getElementById("video");
        if (IsPlaying) {
          vid.pause();
        } else {
          vid.play();
        }
        setIsPlaying(!IsPlaying);
      };
    
    return(
        <div>
        <h1 className="text-sm p-2 text-white bg-black">SHOW REEL</h1>
        <div className=" md:p-2 w-full h-auto bg-gradient-to-t from-blue-950 to-black text-white overflow-hidden">
         <div className="md:w-1/2 w-screen md:h-auto pb-8 m-auto">
          <video
            ref={videoRef}
            src={props}
            className="w-screen h-full object-cover"
            id="video"
            onClick={togglePlayBtn}
          ></video>
          <div className="flex justify-center gap-10 bg-black p-3 font-semibold">
            <button onClick={togglePlayBtn} className="flex gap-2">
              {IsPlaying ? pauseIcon() : playIcon()}
            </button>
            <button onClick={handleFullscreen} className="flex gap-2">
              <MdFullscreen className="text-2xl" /> View Full screen
            </button>
          </div>
        </div>
       </div>
       </div>
    )
}
export default Showreel