
const Animations = ({props})=>{
    return(
        <div className="">
             {Object.entries(props.images).map(([category, images], index) => (
            <div key={index} className="category-section">
              <h2 className="text-white text-center py-3 md:text-2xl uppercase">{category}</h2>
              <div className="grid md:grid-cols-3 gap-2 mx-2 md:m-auto border-b-4 overflow-hidden p-2 bg-black rounded-md bg-opacity-50">
                {images.length > 0 ? (
                  images.map((image, imgIndex) => (
                    <div key={imgIndex} className=" cursor-pointer hover:shadow-md hover:shadow-white">
                        <video className="h-96 w-96 2xl:w-[500px] rounded-md" controls>
                <source src={image} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
                    </div>
                  ))
                ) : (
                  <p style={{ color: "#777", fontstyle: "italic" }} className="text-sm">
                    No videos Yet
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
    )
}
  
export default Animations