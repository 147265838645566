const ThreeDSculptures = ({ props }) => {

  // Helper function to check if a URL points to a video file
  const isVideo = (url) => {
    const videoExtensions = ['.mp4', '.mov', '.avi', '.mkv', '.webm'];
    return videoExtensions.some((ext) => url.toLowerCase().endsWith(ext));
  };

  return (
    <div>
      {Object.entries(props.images).map(([category, media], index) => (
        <div key={index} className="category-section">
          <h2 className="text-white text-center py-3 md:text-2xl uppercase">
            {category}
          </h2>
          <div className="grid md:grid-cols-4 gap-2 mx-2 md:m-auto border-b-4 overflow-hidden p-2 bg-black rounded-md bg-opacity-50">
            {media.length > 0 ? (
              media.map((item, itemIndex) => (
                <div
                  key={itemIndex}
                  className="cursor-pointer hover:shadow-md hover:shadow-white"
                >
                  {isVideo(item) ? (
                    <video
                      className="h-96 w-96 2xl:w-[500px] rounded-md"
                      controls
                    >
                      <source src={item} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      src={item}
                      alt="3D sculpture"
                      className=" h-60 w-96 2xl:w-[500px] rounded-md"
                    />
                  )}
                </div>
              ))
            ) : (
              <p
                style={{ color: "#777", fontStyle: "italic" }}
                className="text-sm"
              >
                No media yet
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ThreeDSculptures;
